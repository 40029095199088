/* Generated automatically */

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-BlackItalic.ttf');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Black.ttf');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-ExtraBold.ttf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-ExtraLightItalic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-ExtraLight.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Italic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-LightItalic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Light.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-MediumItalic.ttf');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Regular.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-ThinItalic.ttf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('assets/fonts/montserrat/Montserrat-Thin.ttf');
	font-weight: 300;
	font-style: normal;
}

