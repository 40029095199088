/* Generated automatically */

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-BlackItalic.ttf');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Black.ttf');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-BoldItalic.ttf');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-ExtraBold.ttf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-ExtraLightItalic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-ExtraLight.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Italic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-LightItalic.ttf');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Light.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-MediumItalic.ttf');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Regular.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-SemiBoldItalic.ttf');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-ThinItalic.ttf');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Poppins';
	src: url('assets/fonts/poppins/Poppins-Thin.ttf');
	font-weight: 300;
	font-style: normal;
}

