.paragraph {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.title {
  display: -webkit-box;
  overflow: hidden;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
