.container {
  position: relative;

  .spinner {
    position: relative;

    min-width: 40vw;
    min-height: 50vh;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 90vw;
    max-height: 80vh;
  }
}
