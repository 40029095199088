.svg {
  max-width: 100%;
  max-height: 100%;

  fill: transparent;
  stroke: rgba(0, 0, 0, 0.10);

  circle {
    transition: stroke-dashoffset 0.25s linear;
  }
}
