$scrollbar-width: 8px;

.wrapper {
  height: 100%;
  overflow: visible;

  .content {
    position: relative;
    overflow: auto;

    .grabbed {
      transition: none;
    }

    &:not(.grabbed) {
      transition: transform 75ms linear;
    }
  }

  .scrollbar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: $scrollbar-width;

    .thumb {
      position: absolute;
      top: 0;
      right: 0;
      left: 2px;
      width: $scrollbar-width - 4;
      background-color: rgba(0, 0, 0, 0.20);

      &:hover, &.grabbed {
        background-color: rgba(0, 0, 0, 0.40);
      }
    }
  }
}
