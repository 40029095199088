$url: '/assets/icons/pin.svg';

.marker {
  background-image: url($url);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  text-align: center;
  overflow: visible;
}
